<template>
    <Row class="page3">
        <Col style="width:26%;height: 95.5%;">
            <div class="left">
                <span class='title'><span class="title-text">{{picsTitle}}</span></span>
                <span class="angle1"></span>
                <span class="angle2"></span>
                <span class="angle3"></span>
                <span class="angle4"></span>
                <div class="left1" style="height:30%;padding-top: 7%">
                    <div style="height: 80%;display: flex;">
                        <div style="height: 100%; width: 48%;">
                            <pie ref="chart3" id="pie_1"></pie>
                        </div>
                        <div style="height: 100%; width: 48%;">
                            <pie ref="chart4" id="pie_3"></pie>
                        </div>
                    </div>
                </div>
                <div class="left1" style="height:43%;">
                    <red-pocket ref="redPocket"></red-pocket>
                </div>
                <div style="width: 100%; height:24%;">
                    <div style="width:100%; height:100%;">
                        <channel-bar
                            id="left_5"
                            :data="schoolData1"
                            title="覆盖学校数量"
                            ref="channelBar2"
                        ></channel-bar>
                      <channel-bar
                          id="left_5"
                          :data="schoolData2"
                          ref="channelBar3"
                      ></channel-bar>
                    </div>
                </div>
            </div>
        </Col>

        <Col style="width:48%;padding:0 1%;height: 70%;">
          <div class="description">
                        <span class='title'><span class="title-text">{{picsTitle}}</span></span>
                        <span class="angle1"></span>
                        <span class="angle2"></span>
                        <span class="angle3"></span>
                        <span class="angle4"></span>
                        <div class="describeList">
                          <span>{{describeList[chooseIndex]}}</span>
                        </div>
          </div>
            <div class="center-top">
                <china-map ref="chinaMap" :resdata="resdata"></china-map>
            </div>
        </Col>
        <Col style="width:26%;height: 108%;margin-top: 8.2%">
            <div class="right-1">
                <div class="right1-1">
                    <span class='title'><span class="title-text">{{picsTitle}}</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                    <webcasts-risk ref='webcastsRisk'></webcasts-risk>
                </div>
            </div>
            <div class="right-2">
                <div class="right-pics">
                    <span class='title'><span class="title-text">{{ picsTitle }}</span></span>
                    <span class="angle1"></span>
                    <span class="angle2"></span>
                    <span class="angle3"></span>
                    <span class="angle4"></span>
                  <workPics ref="workPic" :isBig="false"></workPics>
                </div>
              <div class="right-pics" style="margin-left: 3%">
                <span class='title'><span class="title-text">荣誉图片</span></span>
                <span class="angle1"></span>
                <span class="angle2"></span>
                <span class="angle3"></span>
                <span class="angle4"></span>
                <honorPics ref="honor" :isLarge="false"></honorPics>
              </div>
            </div>
        </Col>
      <div class="tabNav">
        <div v-for="(item,index) in workName" :key="index" @click="chooseNav(item,index)" :class="index==chooseIndex?'on_workname':'workname'">{{item}}</div>
      </div>
    </Row>
</template>

<script>
const chinaMap  = () => import('./components/chinaMap');
const channelBar = ()=> import('./components/page3/channelBar');
// const distributionSolider = ()=> import('./components/page3/distributionSolider');
const pie = ()=> import('./components/pie');
const redPocket = ()=>import('./components/page3/redPocket');
const webcastsRisk = ()=> import('./components/page3/webcastsRisk');
// const deviceSafeRisk = ()=> import('./components/page3/deviceSafeRisk');
const workPics = () => import('./components/page2/workPictures');
const honorPics = ()=> import('./components/page2/HonoraryPics.vue');
// const doubleRing = ()=> import('./components/page3/doubleRing');
// const hotWords =() => import('./components/page3/hotWords');

export default {
    name: 'page3',
    components: {
        chinaMap,
        channelBar,
        // distributionSolider,
        pie,
        redPocket,
        webcastsRisk,
        // deviceSafeRisk,
        workPics, //项目图片
        honorPics //荣誉图片
        // doubleRing,
        // hotWords
    },
    data() {
        return {
          //tabNav子菜单
          workName:['乡村园长希望+','五美好少年','守望湘间的花蕾','倾听湘声,为爱赋能','"伴你上大学"三湘学子圆梦计划','社区儿童素养','雨花区家庭教育指导服务中心',],
          chooseIndex:0,
          picsTitle:'', //右下角图片标题
          describeList:[
            '为助力乡村教育振兴和教育振兴乡村工作，更好促进城乡教育优质均衡发展，服务科教强省和高质量教育体系建设，湖南省教育基金会联合长沙善行社会工作服务中心和长沙市雨花区群英公益发展促进中心发起“乡村园长希望+”公益项目。本项目主要聚焦乡村园长再教育问题，通过集中培训、公益帮带、互换性跟岗学习、样板幼儿园、区域工作室、乡村园长发展论坛和覆盖性培训等多种方式，帮助乡村幼儿园园长实现专业蜕变。三年来，项目得到了长沙市学前教育协会的大力支持，在一大批幼教志愿者及爱心人士的助力下，项目取得显著成效，赋能4700余名乡村幼儿园园长，5500余所乡村幼儿园，超过80万名乡村幼儿及家庭因此受益。',
            '在共青团长沙市委、长沙市文明办、长沙市教育局等部门的指导下，长沙市青少年发展基金会、共青团雨花区委、雨花区文明办、雨花区教育局等部门共同发起，由长沙群英会（全国最佳志愿服务组织）在雨花区洞井街道试点开启“五美好少年”公益项目，项目以青少年快乐、健康、均衡成长为目标，以五育教育为核心，融合了自然、科创、体能、红色教育等各类主题，再结合“1+五+N”的项目模式，即融合1个家门口的青少年素质教育实践平台；“五育”课程体系；N方街道、社区、学校、家庭、社会等资源，赋予青少年全面发展能力，缓解青少年家庭、学校、社区、社会关于青少年成长过程中教育压力和培育焦虑，全面打造社会力量介入青少年综合素质教育的创新品牌。',
            '为响应国家政策，贯彻落实新修订的《中华人民共和国未成年人保护法》，以及教育部6月1日签发的《未成年人学校保护规定》。鉴于乡村女孩的健康卫生、心理安全问题以及近年来屡屡爆出的女孩被性侵事件，以女孩的赋权和增能为基本点，以立足长沙辐射全省模式将5年设置为一项目周期，在湖南省内实现每年资助1000名女孩，培育守望讲师以“一包五课”形式为适龄女孩提供守望包和知识手册，配套5节社会性别与生理健康卫生课程（课程同时面向男孩），并同步配套困境帮扶、司法援助、医疗服务等活动。2021年，该项目已在湖南省7个地市州及广西壮族自治区贺州市进行试点。',
            '在教育乡村振兴的背景之下，在湖南省教育基金会的指导下，群英会携手湖南省人艺汉语艺术实践中心发起“倾听湘声 为爱赋能”公益项目。以“公益”为纽带，聚焦乡村小学语文教师朗读能力提升问题，旨在将国内著名的语言表演艺术家、语言基础训练资深的教师等专业力量整合起来，通过通识性课堂+集中培训+种子教师成长计划赋能乡村小学语文教师朗读素养和朗读教学能力提升，让乡村的孩子享受到更优质的朗读教学资源，为促进城乡教育均衡发展添动力。',
            '在湖南省教育基金会指导下，群英会携手湖南三湘银行发起--伴你上大学·“三湘学子”圆梦计划，为品学兼优的大学生搭建成长与帮扶平台。通过开展学业资助、成长互助、就业交流等活动，让他们不会因经济问题终止大学梦，并获得更多锻炼和成长机会，助力实现个人梦想与社会价值。同时，以助学为起点，搭建平台为宗旨，希望通过三湘伙伴计划把每一届三湘学子联结起来，让每一位“三湘学子”成为“三湘学子”圆梦计划的志愿者，参与项目的共建活动，让公益成为传承的支点。',
            '社区儿童素养教育项目旨在将教育时间延伸到社区和家庭，为青少年、儿童提供优质的素养教育服务。该项目以“梦想课程”为主要服务内容，对儿童的求真、求知天性进行引导。项目内容设计生动有趣，可以充分调动儿童参与活动的主动性和积极性，让孩子们在轻松愉悦的环境中逐渐树立正确的世界观、人生观、价值观，学习真知，积极进步。',
            '在长沙市妇女联合会、长沙市雨花区人民政府指导下，长沙市雨花区妇女联合会、长沙市雨花区群英公益发展促进中心联合主办雨花区家庭教育指导服务中心项目，项目通过整合多方资源，以“一个平台”+“两大理念”+“三个版块”+“四个建设 ”+“五大服务“+”N个基地”模式，构建政、家、校、社协同育人平台，建设规范化、系统化家庭教育指导服务体系，促进家庭教育指导工作的科学化、规范化发展，充分发挥家庭教育在培养新一代栋梁之才中更重要的基础性作用，扎实推动雨花区家庭教育工作的全面发展，打造长沙市乃至湖南省家庭教育服务品牌。'
          ],
          sportArr:[], //饼图活动人次数组
          serviceArr:[], //饼图服务人次数组
          schoolList:[], //覆盖学校数量
          //地图展示项目数据
          resdata:[
            {
              name: '长沙市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '76',
                  serviceCount: '982',
                  volunteerCount: '148',
                  schoolCount: '1082',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '312',
                  serviceCount: '12975',
                  volunteerCount: '32',
                  schoolCount: '5',
                  money: '20',
                  attent: '2',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '14',
                  serviceCount: '1420',
                  volunteerCount: '12',
                  schoolCount: '13',
                  money: '94840',
                  attent: '0',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '116',
                  serviceCount: '3480',
                  volunteerCount: '0',
                  schoolCount: '4',
                  money: '10.52',
                  attent: '2',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '4',
                  serviceCount: '4266',
                  volunteerCount: '111',
                  schoolCount: '7',
                  money: '94800',
                  attent: '5',
                },
              ]
            },
            {
              name:'株洲市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '17',
                  serviceCount: '427',
                  volunteerCount: '60',
                  schoolCount: '500',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '2',
                  serviceCount: '200',
                  volunteerCount: '9',
                  schoolCount: '2',
                  money: '0',
                  attent: '1',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '116',
                  serviceCount: '3480',
                  volunteerCount: '0',
                  schoolCount: '4',
                  money: '10.52',
                  attent: '2',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'湘潭市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '15',
                  serviceCount: '292',
                  volunteerCount: '55',
                  schoolCount: '300',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'衡阳市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '9',
                  serviceCount: '168',
                  volunteerCount: '30',
                  schoolCount: '200',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '3',
                  serviceCount: '168',
                  volunteerCount: '2',
                  schoolCount: '3',
                  money: '0',
                  attent: '1',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'常德市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '28',
                  serviceCount: '438',
                  volunteerCount: '80',
                  schoolCount: '525',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'益阳市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '10',
                  serviceCount: '209',
                  volunteerCount: '42',
                  schoolCount: '209',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '3',
                  serviceCount: '349',
                  volunteerCount: '4',
                  schoolCount: '3',
                  money: '0',
                  attent: '1',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'娄底市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '12',
                  serviceCount: '267',
                  volunteerCount: '51',
                  schoolCount: '295',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'郴州市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '9',
                  serviceCount: '199',
                  volunteerCount: '35',
                  schoolCount: '285',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '1',
                  serviceCount: '30',
                  volunteerCount: '2',
                  schoolCount: '1',
                  money: '0',
                  attent: '1',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'永州市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '10',
                  serviceCount: '245',
                  volunteerCount: '60',
                  schoolCount: '245',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '1',
                  serviceCount: '140',
                  volunteerCount: '6',
                  schoolCount: '1',
                  money: '0',
                  attent: '1',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'怀化市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '22',
                  serviceCount: '256',
                  volunteerCount: '74',
                  schoolCount: '400',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '1',
                  serviceCount: '186',
                  volunteerCount: '5',
                  schoolCount: '1',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'湘西土家族苗族自治州',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '15',
                  serviceCount: '377',
                  volunteerCount: '57',
                  schoolCount: '495',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '2',
                  serviceCount: '172',
                  volunteerCount: '2',
                  schoolCount: '2',
                  money: '0',
                  attent: '1',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'张家界市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '10',
                  serviceCount: '246',
                  volunteerCount: '50',
                  schoolCount: '282',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'岳阳市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '10',
                  serviceCount: '285',
                  volunteerCount: '46',
                  schoolCount: '297',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '58',
                  serviceCount: '1740',
                  volunteerCount: '0',
                  schoolCount: '2',
                  money: '5.26',
                  attent: '1',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
            {
              name:'邵阳市',
              value:1,
              choiceIndex:1,
              list:[
                {
                  title: '乡村园长希望+',
                  soprtcount: '14',
                  serviceCount: '309',
                  volunteerCount: '64',
                  schoolCount: '385',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '五美好少年',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '守望湘间的花蕾',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '倾听湘声 为爱赋能',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '"伴你上大学"三湘学子圆梦计划',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '社区儿童素养',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
                {
                  title: '雨花区家庭教育指导服务中心',
                  soprtcount: '0',
                  serviceCount: '0',
                  volunteerCount: '0',
                  schoolCount: '0',
                  money: '0',
                  attent: '0',
                },
              ]
            },
          ],
          //项目图片数组
            workPicsList:[
              {
                name:'“乡村园长希望+”公益项目宣传照片',
                picsList:[
                  require('@/assets/educationPubWelfare/RuralHeaderWish/1.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/2.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/3.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/4.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/5.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/6.png'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/7.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/8.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/9.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/10.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/11.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/12.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/14.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/15.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/16.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/17.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/18.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/19.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/20.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/21.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/22.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/23.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/24.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/25.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/26.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/27.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/28.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/29.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/30.jpg'),
                  require('@/assets/educationPubWelfare/RuralHeaderWish/31.jpg'),
                ]
              },
              {
              name:'20221129五美好少年照片',
              picsList:[
                require('@/assets/educationPubWelfare/perfectBoysPics/1.jpg'),
                require('@/assets/educationPubWelfare/perfectBoysPics/2.jpg'),
                require('@/assets/educationPubWelfare/perfectBoysPics/3.jpg'),
                require('@/assets/educationPubWelfare/perfectBoysPics/4.jpg'),
                require('@/assets/educationPubWelfare/perfectBoysPics/5.jpg'),
                require('@/assets/educationPubWelfare/perfectBoysPics/6.png'),
                require('@/assets/educationPubWelfare/perfectBoysPics/7.jpg'),
                require('@/assets/educationPubWelfare/perfectBoysPics/8.jpg'),
                require('@/assets/educationPubWelfare/perfectBoysPics/9.jpg'),
                require('@/assets/educationPubWelfare/perfectBoysPics/10.jpg'),
                require('@/assets/educationPubWelfare/perfectBoysPics/11.jpg'),
              ]
            },
              {
                name:'守望湘间的花蕾照片',
                picsList:[
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/1.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/2.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/3.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/4.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/5.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/6.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/7.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/8.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/9.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/10.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/11.jpg'),
                  require('@/assets/educationPubWelfare/waitingXiangPavilion/12.jpg'),
                ]
              },
              {
                name:'倾听湘声,为爱赋能',
                picsList:[]
              },
              {
                name:'"伴你上大学"三湘学子圆梦计划',
                picsList:[]
              },
              {
              name:'社区儿童素养教育项目照片',
              picsList:[
                require('@/assets/educationPubWelfare/comuityChildEducate/1.jpg'),
                require('@/assets/educationPubWelfare/comuityChildEducate/2.png'),
                require('@/assets/educationPubWelfare/comuityChildEducate/3.png'),
                require('@/assets/educationPubWelfare/comuityChildEducate/4.png'),
                require('@/assets/educationPubWelfare/comuityChildEducate/5.png'),
                require('@/assets/educationPubWelfare/comuityChildEducate/6.jpg'),
                require('@/assets/educationPubWelfare/comuityChildEducate/7.jpg'),
                require('@/assets/educationPubWelfare/comuityChildEducate/8.jpg'),
                require('@/assets/educationPubWelfare/comuityChildEducate/9.jpg'),
                require('@/assets/educationPubWelfare/comuityChildEducate/10.jpg'),
              ]
            },
              {
              name:'雨花区家庭教育指导服务中心照片',
              picsList:[
                require('@/assets/educationPubWelfare/yuhuaFamilyEducate/1.jpg'),
                require('@/assets/educationPubWelfare/yuhuaFamilyEducate/2.jpg'),
                require('@/assets/educationPubWelfare/yuhuaFamilyEducate/3.jpg'),
                require('@/assets/educationPubWelfare/yuhuaFamilyEducate/4.jpg'),
                require('@/assets/educationPubWelfare/yuhuaFamilyEducate/5.jpg'),
                require('@/assets/educationPubWelfare/yuhuaFamilyEducate/6.jpg'),
                require('@/assets/educationPubWelfare/yuhuaFamilyEducate/7.jpg'),
                require('@/assets/educationPubWelfare/yuhuaFamilyEducate/8.jpg'),
                require('@/assets/educationPubWelfare/yuhuaFamilyEducate/9.jpg'),
                require('@/assets/educationPubWelfare/yuhuaFamilyEducate/10.jpg'),

              ]
            },
          ],
            data1: [
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '20%',
                    data: {name: "红包转账", value: 45, color: '#0E4CFF'},
                },
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '36%',
                    data: {name: "访问涉赌网络", value: 60, color: '#B405FD'},
                },
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '53%',
                    data: {name: "使用涉赌应用", value: 12, color: '#FE9900'},
                },
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '69%',
                    data: {name: "访问炒股网络", value: 24, color: '#FF6600'},
                },
                {
                    subtitle: '675人（2345次/4533元）',
                    top: '85%',
                    data: {name: "使用炒股应用", value: 21, color: '#7F05FD'}

                }
            ],
            schoolData1: [
                {
                    subtitle: '长沙市',
                    top: '29%',
                    data: {name: "长沙", value:80, color: '#0E4CFF'},
                },
                {
                    subtitle: '株洲市',
                    top: '56%',
                    data: {name: "株洲", value: 48, color: '#FE9900'},
                },
              {
                subtitle: '湘潭市',
                top: '84%',
                data: {name: "湘潭", value: 50, color: '#7F05FD'},
              },
            ],
            schoolData2: [
            {
              subtitle: '衡阳市',
              top: '29%',
              data: {name: "衡阳", value: 46, color: '#087a83'},
            },
            {
              subtitle: '常德市',
              top: '56%',
              data: {name: "常德", value: 40, color: '#c52d5a'},
            },
            {
              subtitle: '益阳市',
              top: '84%',
              data: {name: "益阳", value: 36, color: '#326002'},
            },
          ],
            pieData1: {// 饼图数据1
                title: "活动场次",
                color: '#2C7BFE',
                data: [
                    {
                        value: 76,
                        name: '长沙',
                        itemStyle: {
                            color: '#1456FE'
                        }

                    },
                    {
                        value: 17,
                        name: '株洲',
                        itemStyle: {
                            color: '#00CCFF'
                        }
                    },
                    {
                        value: 15,
                        name: '湘潭',
                        itemStyle: {
                            color: '#142AFE'
                        }
                    },
                    {
                        value: 10,
                        name: '衡阳',
                        itemStyle: {
                            color: '#1493FE'
                        }
                    },
                    {
                        value: 28,
                        name: '常德',
                        itemStyle: {
                            color: '#252448'
                        }
                    },
                  {
                    value: 14,
                    name: '益阳',
                    itemStyle: {
                      color: '#165c62'
                    }
                  },
                  {
                    value: 22,
                    name: '娄底',
                    itemStyle: {
                      color: '#3d3c8f'
                    }
                  },
                  {
                    value: 15,
                    name: '郴州',
                    itemStyle: {
                      color: '#31cddc'
                    }
                  },
                  {
                    value: 12,
                    name: '永州',
                    itemStyle: {
                      color: '#8483d7'
                    }
                  },
                  {
                    value: 10,
                    name: '怀化',
                    itemStyle: {
                      color: '#5ae1ee'
                    }
                  },
                  {
                    value: 10,
                    name: '湘西',
                    itemStyle: {
                      color: '#4d8a8e'
                    }
                  },
                  {
                    value: 10,
                    name: '张家界',
                    itemStyle: {
                      color: '#8e6b8b'
                    }
                  },
                  {
                    value: 10,
                    name: '岳阳',
                    itemStyle: {
                      color: '#e0a4ac'
                    }
                  },

                  {
                    value: 10,
                    name: '邵阳',
                    itemStyle: {
                      color: '#709670'
                    }
                  },
                ],
            },
            pieData2: {// 饼图数据1
            title: "服务人次",
            color: '#2C7BFE',

              data: [
                {
                  value: 76,
                  name: '长沙',
                  itemStyle: {
                    color: '#1456FE'
                  }

                },
                {
                  value: 17,
                  name: '株洲',
                  itemStyle: {
                    color: '#00CCFF'
                  }
                },
                {
                  value: 15,
                  name: '湘潭',
                  itemStyle: {
                    color: '#142AFE'
                  }
                },
                {
                  value: 10,
                  name: '衡阳',
                  itemStyle: {
                    color: '#1493FE'
                  }
                },
                {
                  value: 28,
                  name: '常德',
                  itemStyle: {
                    color: '#252448'
                  }
                },
                {
                  value: 14,
                  name: '益阳',
                  itemStyle: {
                    color: '#165c62'
                  }
                },
                {
                  value: 22,
                  name: '娄底',
                  itemStyle: {
                    color: '#3d3c8f'
                  }
                },
                {
                  value: 15,
                  name: '郴州',
                  itemStyle: {
                    color: '#31cddc'
                  }
                },
                {
                  value: 12,
                  name: '永州',
                  itemStyle: {
                    color: '#8483d7'
                  }
                },
                {
                  value: 10,
                  name: '怀化',
                  itemStyle: {
                    color: '#5ae1ee'
                  }
                },
                {
                  value: 10,
                  name: '湘西',
                  itemStyle: {
                    color: '#4d8a8e'
                  }
                },
                {
                  value: 10,
                  name: '张家界',
                  itemStyle: {
                    color: '#8e6b8b'
                  }
                },
                {
                  value: 10,
                  name: '岳阳',
                  itemStyle: {
                    color: '#e0a4ac'
                  }
                },

                {
                  value: 10,
                  name: '邵阳',
                  itemStyle: {
                    color: '#709670'
                  }
                },
              ],
          },
            resizeFn: null
        }
    },
  methods:{
    //  点击子菜单选择
    chooseNav(item,index){
      this.chooseIndex = index;
      this.picsTitle = item;   //标题随着nav导航选项而变化
      //动态获取图片数据
      this.$refs.workPic.workPicsList2 = this.workPicsList[index].picsList;
      this.$refs.workPic.isChoose = !this.$refs.workPic.isChoose; //点击菜单，右下角图片重新渲染滑动
      this.$refs.workPic.loading=false;
      // 荣誉图片
      // this.$refs.honor.honoraryPicsList = this.workPicsList[index].picsList;
      this.$refs.honor.isChoice = !this.$refs.honor.isChoice; //点击菜单，右下角荣誉图片重新渲染滑动
      this.$refs.honor.load=false; //加载中
      //每次点击导航选项将之前数据置空 再进行下面操作
      this.sportArr= []; //活动场次
      this.serviceArr =[];  //服务人次
      this.$refs.redPocket.data =[]; //志愿者人数
      this.$refs.redPocket.index = index; //index来判断显示志愿者人数还是活动人次
      this.schoolList =[];  //覆盖学校量
      this.$refs.webcastsRisk.socialData = [];  //社会组织参与数
      this.$refs.webcastsRisk.moneyData = [];  //投入款物
      for (var i=0;i<this.resdata.length;i++){
        this.resdata[i].choiceIndex=index; //选择导航栏 把index对应传给choiceIndex 从而获取不同数据
        //社区儿童素养志愿者人数改成服务人次
        if(index==5){
          this.$refs.redPocket.data.push(this.resdata[i].list[index].soprtcount)  //服务人次
        }else{
          this.$refs.redPocket.data.push(this.resdata[i].list[index].volunteerCount) //志愿者人数
        }
        this.sportArr.push(this.resdata[i].list[index].soprtcount)  //活动场次
        this.serviceArr.push(this.resdata[i].list[index].serviceCount) //服务人次
        this.schoolList.push(this.resdata[i].list[index].schoolCount); //覆盖学校数量
        this.$refs.webcastsRisk.socialData.push(this.resdata[i].list[index].attent) //社会组织参与数
        this.$refs.webcastsRisk.moneyData.push(this.resdata[i].list[index].money) //投入款物

        //动态计算每个城市的每个项目总和  (目的：若项目数据都为0，湖南地图显示不同颜色)
        let totalValue = Number(this.resdata[i].list[index].attent) + Number(this.resdata[i].list[index].money)
            + Number(this.resdata[i].list[index].schoolCount) + Number(this.resdata[i].list[index].serviceCount)
            + Number(this.resdata[i].list[index].soprtcount) + Number(this.resdata[i].list[index].volunteerCount)
        this.resdata[i].value = totalValue;
      }

      // 覆盖学校量
      let schoolList1 = this.schoolList.slice(0,3); //左边前三个城市的覆盖学校量
      let schoolList2 = this.schoolList.slice(3,6); //右边第四个到第六个城市的覆盖学校量
      for(var j=0;j<this.schoolData1.length;j++){
        this.schoolData1[j].data.value = schoolList1[j]
        this.schoolData2[j].data.value = schoolList2[j]
      }

      // 循环饼图数组
      for(var k=0;k<this.pieData1.data.length;k++){
        this.pieData1.data[k].value = this.sportArr[k]  //活动场次
        this.pieData2.data[k].value = this.serviceArr[k]  //服务人次
      }
      this.$refs.chart3.data = this.pieData1;  //活动场次
      this.$refs.chart4.data = this.pieData2;  //服务人次
      this.$refs.channelBar2.data = this.schoolData1 //覆盖学校量(1-3)
      this.$refs.channelBar3.data = this.schoolData2 //覆盖学校量(4-6)
      this.$refs.channelBar2.setChart();  //刷新覆盖学校数据
      this.$refs.channelBar3.setChart();  //刷新覆盖学校数据
      this.$refs.chart4.setChart(); //刷新饼图数据
      this.$refs.chart3.setChart(); //刷新饼图数据
      this.$refs.redPocket.setChart();  //刷新志愿者人数数据
      this.$refs.webcastsRisk.setWebcasts();  //刷新社会组织参与数、投入款物数据
      console.log(this.$refs.webcastsRisk.socialData,'this.$refs.webcastsRisk.socialData')


      this.$refs.chinaMap.seriesData = this.resdata
      this.$refs.chinaMap.setMap(); //刷新地图数据
    },
  },
    mounted() {
      setTimeout(()=>{
        this.chooseNav(this.workName[0],0);
      },100)
      // this.resizeFn = this.$debounce(()=> {
      //       // 通过捕获系统的onresize事件触发我们需要执行的事件
      //      this.$refs.channelBar1.setChart();
      //      this.$refs.distributionSolider1.setChart();
      //      this.$refs.channelBar2.setChart();
      //      this.$refs.distributionSolider2.setChart();
      //       //this.$refs.pies.setPies();
      //      this.$refs.redPocket.setPocket();
      //      this.$refs.webcastsRisk.setWebcasts();
      //      this.$refs.deviceSafeRisk.setDeviceSafe();
      //      this.$refs.ring1.drawRing();
      //      this.$refs.ring2.drawRing();
      //      this.$refs.ring3.drawRing();
      //       for (let i = 1; i < 9; i++) {
      //          this.$refs['chart' + i].setChart()
      //
      //       }
      //      this.$refs.chinaMap.setMap();
      //     // this.$refs.hnPronvice.mapEchartsInit();
      //      this.$refs.hotWords.setChart();
      //
      //   }, 500)
      //   window.addEventListener('resize', this.resizeFn)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeFn)
    }
}
</script>

<style lang="less" scoped>
.page3 {
    height: 100%;
    width: 100%;
    padding: 8px 10px 20px;
    background: #03044A;
    overflow: hidden;
  position: relative;
  .tabNav{
    width: auto;
    margin: 0 auto;
    height: 4.5%;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;
    position: absolute;
    bottom: -2.5%;
    left: 26%;
    //justify-content: space-around;
    .workname{
      color:#fff;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 38px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      background-color: #4b92e1;
      border-right:1px solid #fff;
      font-family: "华文细黑", Courier New, Courier, monospace;
    }
    .on_workname{
      color:#4b92e1;
      font-size: 15px;
      width: auto;
      height: 100%;
      line-height: 35px;
      min-width: 90px;
      padding: 0 10px;
      text-align: center;
      border-radius: 3px;
      background-color: #fff;
      font-family: "宋体", "Times New Roman", Times, serif;
    }
  }
    .ivu-col {
        height: 100%;
        float: left;
    }

    .left, .right1-1,.right-pics,.center-bottom,.description {

        height: 100%;
        border: 1px solid #0D2451;
        position: relative;
        background: #151456;
        box-sizing: border-box;

        #left_5 {
            height: 100%;
            width: 45%;
            float: left;
        }

        #left_6 {
            height: 100%;
            width: 55%;
            float: left;
        }

        .circular {
            height: 100%;

            .canvas {
                height: 100%;
                width: 30%;
                float: left;

                .subtitle {
                    font-size: 12px;
                    font-weight: bold;
                    color: #fff;
                    height: 25px;
                    padding: 10px 0 ;
                    text-align: center;
                }

                .canvasList {
                    height: calc(~'100% - 25px');
                    text-align: center
                }
            }
        }

        .left1 {
            border-bottom: 1px solid #0D2451;
            background: #151456;
        }

        .title {
            position: absolute;
            display: inline-block;
            color: #6EDDF1;
            border: 2px solid #6EDDF1;
            height: 18px;
            padding: 0 2px;
            left: 50%;
            transform: translate(-50%, -50%);

            .title-text {
                position: relative;
                font-size: 16px;
                background: #09102E;
                display: inline-block;
                padding: 0 4px;
                white-space: nowrap;
                transform: translateY(-5px);
                font-family: "宋体", "Times New Roman", Times, serif;
            }
        }
    }

  .right-pics{
    width: 50%;
  }

    .center-top {
        height: 115%;
    }

  .description{
    background: none!important;
    width: 158% !important;
    height: 24%!important;
    box-sizing: border-box;
    padding-bottom: 2%;
  }
  .describeList{
    text-indent: 3%;
    display: flex;
    height: 96%;
    width: 98%;
    margin: 1% auto;
    align-items: center;
    color:rgb(167,198,235);
    line-height: 27px;
    font-size: 15px;
    //overflow-y: scroll;
    font-family: "华文细黑", Courier New, Courier, monospace;
    span{
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
  .describeList::-webkit-scrollbar {/*滚动条整体样式*/
    width: 4px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .describeList::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    background: #a1b3dc;
  }
  .describeList::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    background: rgb(8,25,79);
  }

    .center-bottom {
        height: 40%;

        .bottom-radars {
            height: 55%;
        }

        .bottom-bars {
            height: 45%;
        }
    }

    .right-1 {
        height: 30%;

        .right1-1 {

            height: 92%;
        }
    }

    .right-2 {
        height: 40%;
        display: flex;
    }
}
</style>
